import request from "../utils/request";

export function get_tcpass_store_list(data: JSON){
    return request({
        url: "/getTcpassStoreList",
        method: "post",
        data: data
    });
}

export function get_tcpass_store_detail(data: JSON){
    return request({
        url: "/getTcpassStoreDetail",
        method: "post",
        data: data
    });
}

export function save_tcpass_store_detail(data: FormData){
    return request({
        url: "/saveTcpassStoreDetail",
        method: "post",
        data: data
    });
}